import { CheckCircleOutlined, SelectOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Button, Input, Modal, Spin, Tooltip } from "antd";
import React from "react";
import { useState } from "react";
import { catSubType } from "../../config";
import {
  CLONE_PRODUCT,
  FETCH_FILTER_CATEGORYLIST_BY_PRODUCT,
} from "../../graphql/modules";
import { notify } from "../../util";
import CloneProdDescription from "./CloneDescription/CloneDescription";

// const options = {
//   maxSizeMB: 1,
//   maxWidthOrHeight: 1920,
//   useWebWorker: true,
// };

export const CloneProduct = ({
  // category,
  productId,
  sizeTypeParent,
  productPrice,
  isAccessories,
  parentId,
}) => {
  const [visible, setVisible] = useState(false);
  const [priceModalVisible, setPriceModalVisible] = useState(false);
  const [catSubId, setCatSubId] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState(productPrice);
  const [sizeType, setSizeType] = useState("");

  const [fileList, setFileList] = useState([]);
  const [descriptions, setDescription] = useState([]);
  const [gallery, setGallery] = useState([]);

  // const [categoryList, setCategorylist] = useState([]);

  // fetch all category and subcateogry name
  const [GetAllCategory, { data: allCategory, loading }] = useLazyQuery(
    FETCH_FILTER_CATEGORYLIST_BY_PRODUCT,
    { fetchPolicy: "network-only" }
  );
  // const { data: allCategory, loading } = useQuery(FETCH_CATEGORY);
  const category = allCategory?.FetchCategoryListExceptProduct || [];

  const openModal = () => {
    GetAllCategory({ variables: { productId } });
    setVisible(true);
  };

  const categoryList = categoryListFilterByParentProduct(
    category,
    sizeTypeParent,
    isAccessories
  );
  // setCategorylist(res);

  const closeModal = () => {
    setVisible(false);
    onClosePriceModal();
  };

  const [CloneProductMutation, { loading: cloneLoading }] =
    useMutation(CLONE_PRODUCT);
  const onSelectToPaste = async () => {
    const formData = {};
    if (sizeType === catSubType.bottom && sizeType !== sizeTypeParent) {
      formData.bottomPrice = price;
      formData.bottomDescriptions = descriptions;
    } else if (sizeType === catSubType.top && sizeType !== sizeTypeParent) {
      formData.topPrice = price;
      formData.topDescriptions = descriptions;
    }
    formData.sizeType = sizeTypeParent;
    try {
      const {
        data: { AddExistingProduct },
      } = await CloneProductMutation({
        variables: {
          productId,
          subcategoryId: type !== "category" ? catSubId : "",
          categoryId: type === "category" ? catSubId : "",
          productInput: { ...formData },
        },
      });
      notify(AddExistingProduct, closeModal());
      if (AddExistingProduct.success) {
        // refetch();
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onClosePriceModal = () => {
    setPriceModalVisible(false);
    setCatSubId("");
    setType("");
    setPrice("");
    setGallery([]);
    setSizeType("");
    setDescription([]);
    setFileList([]);
  };

  const checkCatSubType = (id, type, checkSizeType = catSubType.fullSet) => {
    setCatSubId(id);
    setType(type);
    setSizeType(checkSizeType);
    if (checkSizeType !== sizeTypeParent && checkSizeType !== "MIXED") {
      setPriceModalVisible(true);
    }
  };

  // const onRemove = (name) => {
  //   let images = gallery.filter((item) => item !== name);
  //   setGallery(images);
  //   setFileList(images);
  // };

  // image upload
  // const [SingleUplaodMutation, { loading: uploadLoading }] =
  //   useMutation(FILE_UPLOAD);
  // const handleUploadGalleryClone = async (file) => {
  //   let images = [...gallery] || [];
  //   try {
  //     const compressedFile = await imageCompression(file, options);
  //     const {
  //       data: { SingleUpload },
  //     } = await SingleUplaodMutation({
  //       variables: {
  //         file: compressedFile,
  //       },
  //     });
  //     if (SingleUpload.success) {
  //       images.push(SingleUpload.filename);
  //       setGallery([...images]);
  //       setFileList([...images]);
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  //   // e.persist();
  // };

  // const handleUplaodGalleryMultipleClone = async (e) => {
  //   const MAX_LENGTH = 4 - gallery.length;
  //   if (Array.from(e.target.files).length > MAX_LENGTH) {
  //     e.preventDefault();
  //     message.warn("Cannot upload files more than 4");
  //     return;
  //   }

  //   const { files = [] } = e.target;
  //   let images = [...gallery] || [];

  //   for (var i = 0; i < files.length; i++) {
  //     try {
  //       const compressedFile = await imageCompression(files[i], options);
  //       const {
  //         data: { SingleUpload },
  //       } = await SingleUplaodMutation({
  //         variables: {
  //           file: compressedFile,
  //         },
  //       });
  //       if (SingleUpload.success) {
  //         images.push(SingleUpload.filename);
  //         setGallery([...images]);
  //         setFileList([...images]);
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   }
  // };

  const onDescriptiuonAdd = (desc) => {
    setDescription(desc);
  };

  return (
    <div>
      <Button type="primary" onClick={openModal}>
        Clone
      </Button>
      <Modal
        title="Select"
        open={visible}
        onCancel={closeModal}
        okText="Clone"
        onOk={onSelectToPaste}
        okButtonProps={{
          loading: cloneLoading,
          disabled:
            loading ||
            cloneLoading ||
            !catSubId ||
            // (sizeType !== sizeTypeParent && (!price || gallery?.length === 0)),
            (sizeType !== sizeTypeParent &&
              sizeType !== "MIXED" &&
              (!price || gallery?.length === 0)),
        }}
      >
        <Spin spinning={loading}>
          <div style={{ minHeight: loading ? "300px" : "unset" }}></div>
          {categoryList?.map((item) => {
            if (item?._id !== parentId)
              return (
                <div key={item?._id} className="mb-3">
                  <div>
                    <span className="mr-2">&#8226;</span>
                    <span className="mr-3">{item?.name}</span>{" "}
                    {!item?.subcategory && (
                      <Tooltip title="Select to paste">
                        <SelectOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            checkCatSubType(
                              item?._id,
                              "category",
                              item?.sizeType
                            )
                          }
                        />
                      </Tooltip>
                    )}
                    {item?._id === catSubId && (
                      <CheckCircleOutlined
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          marginLeft: "25px",
                          fontSize: "16px",
                        }}
                      />
                    )}
                  </div>
                  <div className="ml-5">
                    {item?.subcategory?.map((sub) => {
                      if (sub?._id !== parentId) {
                        return (
                          <div key={sub?._id}>
                            <span className="mr-3">{sub?.name}</span>{" "}
                            <Tooltip title="Select to paste">
                              <SelectOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  checkCatSubType(
                                    sub?._id,
                                    "subcategory",
                                    sub?.sizeType
                                  )
                                }
                              />
                            </Tooltip>
                            {sub?._id === catSubId && (
                              <CheckCircleOutlined
                                style={{
                                  color: "green",
                                  fontWeight: "bold",
                                  marginLeft: "25px",
                                  fontSize: "16px",
                                }}
                              />
                            )}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
          })}
        </Spin>
      </Modal>
      <Modal
        open={priceModalVisible}
        onCancel={onClosePriceModal}
        width={700}
        title="Add Info"
        onOk={onSelectToPaste}
        okButtonProps={{
          loading,
          disabled:
            cloneLoading ||
            !catSubId ||
            (sizeType !== catSubType.fullSet && !price),
        }}
      >
        <div className="mb-3">
          <div>
            <label>
              {sizeType === catSubType.top ? "Top Price" : "Bottom Price"}
            </label>
          </div>
          <Input
            value={price}
            onChange={(e) => setPrice(parseFloat(e.target.value) || "")}
          />
        </div>
        {/* <Spin spinning={uploadLoading}>
          <div className="mb-3">
            <CloneGallery
              fileList={fileList}
              handleUploadGalleryClone={handleUploadGalleryClone}
              handleUplaodGalleryMultipleClone={
                handleUplaodGalleryMultipleClone
              }
              onRemove={onRemove}
            />
          </div>
        </Spin> */}

        <div className="mb-3">
          <div>
            <label>Descriptions</label>
          </div>
          <CloneProdDescription
            data={descriptions}
            onChange={onDescriptiuonAdd}
          />
        </div>
      </Modal>
    </div>
  );
};

const categoryListFilterByParentProduct = (
  { categories = [], categoryWithSubcategory = [] },
  sizeTypeParent,
  isAccessories
) => {
  const returnCategory =
    sizeTypeParent !== catSubType.fullSet
      ? categories?.filter(
          (ct) => ct?.sizeType === sizeTypeParent || ct?.sizeType === "MIXED"
        )
      : isAccessories
      ? categories?.filter(
          (ct) =>
            ct?.sizeType === catSubType.fullSet || ct?.sizeType === "MIXED"
        )
      : [...categories];
  const returnSubCategory =
    sizeTypeParent !== catSubType.fullSet
      ? categoryWithSubcategory?.filter(
          (ct) => ct?.sizeType === sizeTypeParent || ct?.sizeType === "MIXED"
        )
      : isAccessories
      ? categoryWithSubcategory?.filter(
          (ct) =>
            ct?.sizeType === catSubType.fullSet || ct?.sizeType === "MIXED"
        )
      : categoryWithSubcategory;

  return [...returnCategory, ...returnSubCategory];
};
