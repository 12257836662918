import { useMutation, useQuery } from "@apollo/react-hooks";
import { Divider, Input, Modal, Spin, Tag } from "antd";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  CancelOrder,
  OrderItemContainer,
  Status,
} from "../components/OrderDetails";
import { DynamicList } from "../components/Shared";
import { OrderStatus } from "../config/constants";
import { FETCH_SINGLE_ORDER, ORDER_STATUS_UPDATE } from "../graphql/modules";
import { AddressRender } from "../util/helper";
import { successNotify, warnNotify } from "../util/notification";

export const OrderDetails = ({ match }) => {
  const orderId = match.params.id;
  // const [status, setStatus] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [preOrderTrackingNumber, setPreOrderTrackingNumber] = useState("");
  const [trackingUrl, setTrackingUrl] = useState("");
  const [preOrderTrackingUrl, setPreOrderTrackingUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [partVisible, setPartVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [cancelationNote, setCancelationNote] = useState("");
  const [visibleForCancel, setVisibleForCancel] = useState(false);

  // fetch order data
  const { data, loading, refetch } = useQuery(FETCH_SINGLE_ORDER, {
    variables: {
      orderId,
    },
  });
  const orderData = data?.FetchSingleOrderAdmin?.result || {};
  const isAllOrder = orderData?.preOrderShippingType && orderData?.shippingType;
  const regularItem =
    data?.FetchSingleOrderAdmin?.result?.items?.filter(
      (ele) => !ele?.isPreOrderItem
    ) || [];
  const preItem =
    data?.FetchSingleOrderAdmin?.result?.items?.filter(
      (ele) => ele?.isPreOrderItem
    ) || [];

  // update status
  const [UpdateOrderStatusMuttaion, { loading: statusLoading }] =
    useMutation(ORDER_STATUS_UPDATE);
  const updateOrderStatus = async (status) => {
    if (status !== getEnum(orderData?.status) && status) {
      let partialShippedItemsId = [];
      regularItem.forEach((element) => {
        partialShippedItemsId.push(element?._id);
      });
      try {
        const {
          data: { UpdateOrderStatus },
        } = await UpdateOrderStatusMuttaion({
          variables: {
            orderId,
            status,
            trackingNumber,
            partialShippedItemsId,
            preOrderTrackingNumber,
            preOrderTrackingUrl,
            trackingUrl,
            cancelationNote,
          },
        });
        if (UpdateOrderStatus.success) {
          successNotify(UpdateOrderStatus.message);
          refetch();
          setVisible(false);
          setPartVisible(false);
          setEdit(false);
          setTrackingUrl("");
          setPreOrderTrackingUrl("");
          setPreOrderTrackingNumber("");
          setTrackingNumber("");
          setCancelationNote("");
          setVisibleForCancel(false);
        } else {
          warnNotify(UpdateOrderStatus.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // control modal
  const closeModal = () => {
    setTrackingUrl("");
    setTrackingNumber("");
    setPreOrderTrackingNumber("");
    setPreOrderTrackingUrl("");
    setVisible(false);
    setPartVisible(false);
  };

  // for tracking order number
  const checkOrderStatus = (status) => {
    if (status === OrderStatus.Partial) {
      setPartVisible(true);
    } else if (status === OrderStatus.Shipped) {
      setVisible(true);
    } else {
      updateOrderStatus(getEnum(status));
    }
  };

  return (
    <DynamicList
      tableHide={true}
      actions={
        <div className="d-flex">
          <h5 className="mr-5">OrderId: {orderData?.orderId}</h5>
          {!loading && (
            <span style={{ fontSize: "16px" }}>
              Email: {orderData?.email || orderData?.user?.email}{" "}
              <span style={{ fontWeight: "bold" }}>
                ({orderData?.isGuestUser ? "Guest" : "Registered"})
              </span>
            </span>
          )}
        </div>
      }
    >
      <Spin spinning={loading}>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="shipping-details">
                <h4>Shipping to:</h4>
                <p style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                  {orderData?.shippingAddress?.firstname +
                    " " +
                    orderData?.shippingAddress?.lastname}
                </p>
                <p style={{ textTransform: "uppercase" }}>
                  {AddressRender(orderData?.shippingAddress).first}
                </p>
                <p style={{ textTransform: "uppercase" }}>
                  {AddressRender(orderData?.shippingAddress).second}
                </p>
                <p>{countryName(orderData?.shippingAddress?.country)}</p>
                <p>Phone number: {orderData?.shippingAddress?.phone}</p>
              </div>
              {orderData?.coupon && (
                <div className="coupon_details mt-3">
                  <h4>Coupon:</h4>

                  <p>
                    Code:{" "}
                    <Link to={`/collaborators/${orderData?.couponOwner?._id}`}>
                      <Tag className="ml-2">{orderData?.coupon?.code}</Tag>
                    </Link>
                  </p>
                  <p>
                    Discount: {orderData?.couponDiscount} USD
                    <span className="ml-2">
                      ({orderData?.coupon?.discountInPercent}%)
                    </span>
                  </p>
                </div>
              )}
              {orderData?.discountOnTotalPrice > 0 && (
                <div className="coupon_details mt-3">
                  <h4>Discount on purchase Product:</h4>
                  <p>Discount: {orderData?.discountOnTotalPrice} USD</p>
                </div>
              )}

              {orderData?.orderNote && (
                <div className="mt-3">
                  <div className="bold">
                    <label>Note:</label>
                  </div>
                  <textarea
                    rows="3"
                    value={orderData?.orderNote}
                    className="w-100 rounded p-2"
                    disabled
                  ></textarea>
                </div>
              )}
            </div>
            <div className="col-6">
              <h4>Billing address:</h4>
              <p style={{ textTransform: "capitalize", fontWeight: "700" }}>
                {orderData?.billingAddress?.firstname +
                  " " +
                  orderData?.billingAddress?.lastname}
              </p>
              <p style={{ textTransform: "uppercase" }}>
                {AddressRender(orderData?.billingAddress).first}
                <br />
                <br />
                {AddressRender(orderData?.billingAddress).second}
              </p>
              <p>{countryName(orderData?.billingAddress?.country)}</p>

              <p>Phone Number: {orderData?.billingAddress?.phone}</p>
              <Divider />

              <Status
                edit={edit}
                isAllOrder={isAllOrder}
                setEdit={setEdit}
                cancelNote={orderData?.cancelationNote}
                totalRefunded={orderData?.totalRefunded || 0}
                checkOrderStatus={checkOrderStatus}
                dataStatus={orderData?.status}
                updateOrderStatus={updateOrderStatus}
              />
              <Divider />
              {OrderStatus.Pending !== orderData?.status &&
                OrderStatus.Canceled !== orderData?.status &&
                OrderStatus.PreOrder !== orderData?.status && (
                  <div className="tracking-order">
                    <h5>
                      Tracking Order:{" "}
                      <a
                        style={{ fontSize: "14px" }}
                        href={orderData?.trackingUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {orderData?.trackingNumber || ""}
                      </a>
                    </h5>
                  </div>
                )}
              <Divider />
              {(orderData?.status === OrderStatus.Pending ||
                orderData?.status === OrderStatus.Canceled ||
                orderData?.status === OrderStatus.PreOrder) && (
                <CancelOrder
                  cancelNote={cancelationNote}
                  setCancelNote={setCancelationNote}
                  statusLoading={statusLoading}
                  updateOrderStatus={updateOrderStatus}
                  setVisibleForCancel={setVisibleForCancel}
                  visibleForCancel={visibleForCancel}
                  status={orderData?.status}
                />
              )}
              <Divider />
            </div>
          </div>
          {isAllOrder ? (
            <div>
              <OrderItemContainer
                orderData={{
                  ...orderData,
                  items: regularItem,
                  trackingUrl: orderData?.preOrderTrackingUrl,
                  trackingNumber: orderData?.preOrderTrackingNumber,
                }}
                title="Stock Item(s)"
                isAllOrder={isAllOrder}
                status={
                  orderData?.status === "Pre order"
                    ? OrderStatus.Pending
                    : orderData?.status
                }
              />
              <Divider />
              <OrderItemContainer
                orderData={{
                  ...orderData,
                  items: preItem,
                  shippingType: orderData?.preOrderShippingType,
                }}
                title="Pre-Order Item(s)"
                status={
                  orderData?.status === "Shipped"
                    ? "Shipped"
                    : OrderStatus.Pending
                }
                isAllOrder={isAllOrder}
              />
            </div>
          ) : (
            <OrderItemContainer
              orderData={{ ...orderData }}
              title="Item(s)"
              status={orderData?.status}
            />
          )}

          <div
            style={{ borderTop: "1px solid #c3c3c3" }}
            className="col-md-5 col-sm-12"
          >
            <div className="mt-3 product">
              <div className="d-flex justify-content-between align-items-center">
                <p>Product price: </p>
                <p>{parseFloat(orderData?.totalPrice).toFixed(2)} USD</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {orderData?.preOrderShippingType ? (
                  "Shipping cherge:"
                ) : (
                  <p style={{ maxWidth: "250px" }}>
                    Shipping charge (
                    {orderData?.shippingType
                      ? orderData?.shippingType
                      : shippingMethodtext(orderData?.shippingType)}
                    ):{" "}
                  </p>
                )}
                <p>{parseFloat(orderData?.shippingCherge).toFixed(2)} USD</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Taxes: </p>
                <p>{parseFloat(orderData?.tax).toFixed(2)} USD</p>
              </div>
              {orderData?.chillfitCreditUsed > 0 && (
                <div className="coupon_details mt-3">
                  <h4 style={{ color: "red" }}>Discount (Chillfit Cash):</h4>
                  <p style={{ color: "red" }}>
                    Paid by Chillfit Cash:{" "}
                    {Number(orderData?.chillfitCreditUsed).toFixed(2)} USD
                  </p>
                </div>
              )}
              <Divider />
              <div className="d-flex justify-content-between align-items-center">
                <h5>Total Price:</h5>
                <div className="details">
                  <h5>
                    {parseFloat(
                      Number(orderData?.grandTotal || 0) -
                        Number(orderData?.chillfitCreditUsed || 0)
                    ).toFixed(2)}{" "}
                    USD
                  </h5>
                </div>
              </div>
            </div>
            <div>
              <p>
                Payment Method:{" "}
                {orderData?.cardType === "paypal" ? (
                  <img
                    src="/assets/img/paypal-logo.png"
                    style={{ width: "50px" }}
                    alt=""
                  />
                ) : (
                  <Tag style={{ fontSize: "16px" }} color="success">
                    {orderData?.cardType}{" "}
                    {orderData?.cardType === "afterpay" && "(Installment)"}
                  </Tag>
                )}
              </p>
              {orderData?.cardType === "stripe" && (
                <p>
                  Card ending <b>{orderData?.cardNo}</b>
                </p>
              )}
            </div>
          </div>
        </div>
      </Spin>
      <Modal
        open={visible}
        onCancel={closeModal}
        onOk={() => updateOrderStatus(getEnum(OrderStatus.Shipped))}
        okButtonProps={{
          disabled: !trackingNumber || !trackingUrl || statusLoading,
          loading: statusLoading,
        }}
      >
        <div className="mb-3">
          <label>Tracking URL*</label>
          <Input
            onChange={(e) => setTrackingUrl(e.target.value)}
            size="large"
            value={trackingUrl || ""}
            placeholder="Order tracking url"
          />
        </div>
        <label>Tracking Id*</label>
        <Input
          onChange={(e) => setTrackingNumber(e.target.value)}
          value={trackingNumber || ""}
          size="large"
          placeholder="Order tracking number"
        />
      </Modal>
      <Modal
        open={partVisible}
        onCancel={closeModal}
        onOk={() => updateOrderStatus(getEnum(OrderStatus.Partial))}
        okButtonProps={{
          disabled:
            !preOrderTrackingNumber || !preOrderTrackingUrl || statusLoading,
          loading: statusLoading,
        }}
      >
        <div className="mb-3">
          <label>Patial Item Tracking URL*</label>
          <Input
            onChange={(e) => setPreOrderTrackingUrl(e.target.value)}
            size="large"
            value={preOrderTrackingUrl || ""}
            placeholder="Order tracking url"
          />
        </div>
        <label>Partial Item Tracking Id*</label>
        <Input
          onChange={(e) => setPreOrderTrackingNumber(e.target.value)}
          value={preOrderTrackingNumber || ""}
          size="large"
          placeholder="Order tracking number"
        />
      </Modal>
    </DynamicList>
  );
};

const getEnum = (status) => {
  switch (status) {
    case OrderStatus.PreOrder:
      return "preOrder";
    case OrderStatus.Partial:
      return "partialShipped";
    case OrderStatus.Shipped:
      return "shipped";
    case OrderStatus.Pending:
      return "preparingToShip";
    case OrderStatus.Canceled:
      return "canceled";
    case OrderStatus.Deleted:
      return "deleted";
    default:
      return "delivered";
  }
};

const shippingMethodtext = (key) => {
  switch (key) {
    case "twoDayAir":
      return "2 Day Air";
    case "oneDayAir":
      return "1 Day Air";
    default:
      return "Statndard";
  }
};

const countryName = (country) => {
  switch (country) {
    case "AU":
      return "Australia";
    case "CA":
      return "Canada";
    default:
      return "United States";
  }
};
