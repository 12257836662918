import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { DynamicList } from "../components/Shared";
import { FETCH_ALL_USER } from "../graphql/modules";
import moment from "moment";
import { FullName } from "../util/helper";
import { Button, Input, Pagination, Select, Switch } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { debounce } from "lodash";
import { format } from "date-fns";

// import { useDispatch } from "react-redux";
// import { logoutUser } from "../store/modules";
// import pckagejson from "../../package.json";

// const VERSION = "WEB_APP_VERSION";
export const AllUser = () => {
  // state inititlization
  const [limit] = useState(20);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});

  const {
    data: csvData,
    refetch: refetchCSV,
    loading: allUserLoading,
  } = useQuery(FETCH_ALL_USER, {
    variables: {
      limit: 1000000000,
      offset: 0,
      ...filter,
    },
  });
  const csvUserData = csvData?.FetchUsersAdmin?.result || [];
  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    return format(new Date(Number(dateStr)), "yyyy-MM-dd");
  };

  const formatDateTime = (dateStr) => {
    if (!dateStr) return "";
    return format(new Date(Number(dateStr)), "yyyy-MM-dd hh:mm:ss a");
  };
  const onDownloadCSV = () => {
    console.log({ csvUserData });
    if (csvUserData.length === 0) {
      alert("No data available to download.");
      return;
    }

    // Transform the data
    const formattedData = csvUserData.map(
      ({
        _id,
        avater,
        chillfitCash,
        firstname,
        lastname,
        birthDate,
        createdAt,
        email,
        phone,
        isGuestUser,
      }) => ({
        "First Name": firstname || "--",
        "Last Name": lastname || "--",
        Email: email || "--",
        Phone: phone || "--",
        "Guest User": isGuestUser ? "Yes" : "No",
        "Birth Date": formatDate(birthDate) || "--",
        "Created At": formatDateTime(createdAt) || "--",
      })
    );

    const csv = Papa.unparse(formattedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "users.csv");
  };

  // const debouncedDownloadCSV = useCallback(
  //   debounce((data) => downloadCSV(data), 300),
  //   []
  // );

  // useEffect(() => {
  //   if (csvUserData?.length) {
  //     // debounce(downloadCSV(csvUserData), 300);
  //     debouncedDownloadCSV(csvUserData);
  //   }
  // }, [allUserLoading, csvUserData]);

  const { data, loading, refetch } = useQuery(FETCH_ALL_USER, {
    variables: {
      limit,
      offset: page - 1,
    },
  });
  const allUser = data?.FetchUsersAdmin?.result || [];
  const total = data?.FetchUsersAdmin?.count || 0;

  const clearSearch = () => {
    setFilter({});
    refetch({ name: "", isGuestUser: undefined });
    refetchCSV({ name: "", isGuestUser: undefined });
  };

  // page handler
  const onChangePagination = (p) => {
    setPage(p);
  };

  // table column
  const column = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (text, record) =>
        moment(Number(record.createdAt)).format("MM/DD/YYYY hh:mm A"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      render: (text, record) => (FullName(record) ? FullName(record) : "---"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (text, record) => (record.phone ? record.phone : "---"),
    },
    {
      title: "BoD",
      dataIndex: "birthDate",
      render: (text, record) =>
        record.birthDate
          ? moment(Number(record.birthDate)).format("DD/MM/YYYY")
          : "---",
    },
    {
      title: "Chillfit Cash",
      dataIndex: "chillfitCash",
      render: (text, record) =>
        `${(record.chillfitCash ? record.chillfitCash : 0).toFixed(2)} USD`,
    },
    {
      title: "User Type",
      render: (text, record) => (record?.isGuestUser ? "Guest" : "Register"),
    },
  ];

  // user type filter
  const handleUserType = (value) => {
    if (value === "all") {
      setFilter({ ...filter, isGuestUser: undefined });
    } else {
      setFilter({ ...filter, isGuestUser: value });
    }
  };
  const handleHasCredit = (value) => {
    if (value) {
      setFilter({ ...filter, hasCredit: true });
    } else {
      // delete filter.hasCredit;
      setFilter({ ...filter, hasCredit: false });
    }
  };

  const searchComponent = (
    <div className="d-flex align-items-center">
      <div className="mr-4">
        <label className="mr-3">Select User type:</label>
        <Select
          style={{ width: "250px" }}
          className="mr-2"
          value={filter.isGuestUser === undefined ? "all" : filter.isGuestUser}
          size="large"
          onChange={handleUserType}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value={false}>Register User</Select.Option>
          <Select.Option value={true}>Guest User</Select.Option>
        </Select>
      </div>
      <div className="mr-3">
        <label className="mr-3">Name</label>
        <Input
          style={{ width: "250px" }}
          size="large"
          placeholder="Name"
          value={filter.name}
          onChange={(e) => setFilter({ ...filter, name: e.target.value })}
        />
      </div>
      <div>
        <Switch
          onChange={handleHasCredit}
          className="mr-3"
          size="default"
          unCheckedChildren="Everyone"
          checkedChildren="With Cash"
        />
      </div>
      <div>
        <Button
          size="large"
          type="primary"
          className="mr-2"
          onClick={() => {
            refetch({ ...filter });
            refetchCSV({ ...filter });
          }}
        >
          Search
        </Button>
        <Button size="large" onClick={clearSearch}>
          Clear
        </Button>
      </div>
    </div>
  );

  let createBtn = (
    <React.Fragment>
      <Link
        to="/customers/subscriber"
        style={{ minWidth: "110px", fontSize: "18px" }}
      >
        Subscriber User <ArrowRightOutlined className="ml-3" />
      </Link>
    </React.Fragment>
  );

  return (
    <DynamicList
      title="All users"
      data={allUser}
      actions={createBtn}
      columns={column}
      loading={loading}
      tableFilter={searchComponent}
      headerAction={
        <Button
          size="large"
          type="primary"
          className="mr-2"
          onClick={onDownloadCSV}
          loading={allUserLoading}
        >
          Download CSV
        </Button>
      }
      pagination={
        <Pagination
          className="pagination"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      }
    />
  );
};
