import React, { useState } from "react";
import { DynamicList } from "../components/Shared";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  FETCH_ORDER_ITEMS_ADMIN,
  SEND_EMAIL_NOTIFICATION_ORDER_ITEM,
  SEND_SHIPPING_LABEL,
  UPDATE_ORDER_ITEM_STATUS,
} from "../graphql/modules";
import moment from "moment";
import { Button, Input, Modal, Pagination, Popconfirm, Tag } from "antd";
import { Link } from "react-router-dom";
import { successNotify } from "../util/notification";
import TextArea from "antd/es/input/TextArea";

export const ReturnOrder = () => {
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [note, setNote] = useState("");
  const [refundModalVisible, setRefundModalVisible] = useState("");
  const [cardType, setCardType] = useState("");
  const [last4OfCard, setLast4OfCard] = useState("");
  const [isShippingLavbelModalVisible, setIsShippingLavbelModalVisible] =
    useState(false);
  const [attachment, setAttachemnt] = useState();

  // fetch order
  const { data, loading, refetch } = useQuery(FETCH_ORDER_ITEMS_ADMIN, {
    variables: {
      limit,
      offset: page - 1,
    },
    // fetchPolicy:"network-only"
  });
  const allOrderReturnItemsList = data?.FetchOrderIReturnAdmin?.result || [];
  const total = data?.FetchOrderIReturnAdmin?.count || 0;

  const onChangePagination = (p) => {
    setPage(p);
  };

  const priceSelect = (data) => {
    if (
      (data?.bottomSizing && data?.topSizing) ||
      (!data?.bottomSizing && !data?.topSizing)
    ) {
      return data?.price;
    } else if (data?.topSizing) {
      return data?.topPrice;
    } else {
      return data?.bottomPrice;
    }
  };

  const calculatePrice = (data) => {
    const price = data?.haveDiscount
      ? parseFloat(
          priceSelect(data) - (priceSelect(data) * data?.discount) / 100
        )
      : parseFloat(priceSelect(data) || 0.0);
    return price;
  };

  const [SendShippingLabel, { loading: shippingLabelLoading }] =
    useMutation(SEND_SHIPPING_LABEL);

  const onSendShippingLabel = async () => {
    try {
      const {
        data: { SendOrderItemLabelAdmin },
      } = await SendShippingLabel({
        variables: {
          orderItemId: isShippingLavbelModalVisible,
          file: attachment,
        },
      });
      if (SendOrderItemLabelAdmin.success) {
        successNotify(SendOrderItemLabelAdmin.message);
        setAttachemnt(false);
        setIsShippingLavbelModalVisible(false);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [AddNewAddressMutation, { loading: statusLoading }] = useMutation(
    UPDATE_ORDER_ITEM_STATUS
  );
  const onSubmitRefund = async ({ orderItemId, returnStatus }) => {
    try {
      const {
        data: { UpdateOrderReturnItemStatusAdmin },
      } = await AddNewAddressMutation({
        variables: {
          orderItemId,
          returnStatus,
          note,
        },
      });
      if (UpdateOrderReturnItemStatusAdmin.success) {
        successNotify(UpdateOrderReturnItemStatusAdmin.message);
        setIsRejectModalVisible(false);
        setNote("");
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [onSendEmails, { loading: emailLoading }] = useMutation(
    SEND_EMAIL_NOTIFICATION_ORDER_ITEM
  );
  const onSendArrivalNotification = async ({ orderItemId, type }) => {
    try {
      const {
        data: { SendOrderItemEmails },
      } = await onSendEmails({
        variables: {
          orderItemId,
          type,
          cardType,
          last4OfCard,
        },
      });
      if (SendOrderItemEmails.success) {
        successNotify(SendOrderItemEmails.message);
        setRefundModalVisible(false);
        setLast4OfCard("");
        setCardType("");
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "order",
      key: "orderId",
      render: (data) => data?.orderId,
    },
    {
      title: "Email",
      dataIndex: "order",
      key: "order_email",
      render: (order) => order?.email,
    },
    {
      title: "Return Date",
      key: "orderReturnDate",
      render: (text, record) =>
        moment(Number(record.returnInitDate)).format("MM/DD/YYYY T hh:MM A"),
    },
    {
      title: "Total Refundable",
      key: "orderReturnDate1",
      render: (_, { totalTax, totalDiscountedPrice }) => (
        <p style={{ margin: 0 }}>
          {console.log({ totalDiscountedPrice, totalTax })}
          {Number((totalDiscountedPrice || 0) + (totalTax || 0)).toFixed(2)} USD
        </p>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "returnPaymentMethod",
      key: "returnPaymentMethod",
      render: (returnPaymentMethod) => (
        <p style={{ margin: 0 }}>
          {returnPaymentMethod === "ORIGINAL_PAYMENTS"
            ? "ORIGINAL PAYMENTS"
            : "CHILLFIT STORE CREDIT"}
        </p>
      ),
    },
    // {
    //   title: "Quantity",
    //   key: "quantity",
    //   render: (_, { quantity, orderItem }) =>
    //     `${quantity}/${orderItem?.quantity || 1}`,
    // },
    {
      title: "Status",
      key: "Statussdf",
      render: (_, record) => getStatus(record.returnStatus),
    },
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <div style={{ minWidth: "255px", width: "255px" }}>
            <Link to={`/orders/${record?.order?._id}`} className="mt-2 mr-3">
              <Button type="primary">View</Button>
            </Link>
            <Popconfirm
              title="Are you sure?"
              okType="danger"
              disabled={record?.returnStatus !== "Returning"}
              onConfirm={(e) => {
                onSubmitRefund({
                  orderItemId: record._id,
                  returnStatus: "Accepted",
                });
                e.stopPropagation();
              }}
            >
              <Button
                className="mt-2 mr-3"
                type="primary"
                danger
                disabled={record?.returnStatus !== "Returning"}
              >
                Accept
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              okType="danger"
              // disabled={
              //   record?.returnStatus !== "Returning" &&
              //   !record?.hasArrived
              // }
              disabled={record?.returnStatus === "Refunded"}
              onConfirm={(e) => {
                setIsRejectModalVisible(record._id);
                e.stopPropagation();
              }}
            >
              <Button
                type="default"
                danger
                disabled={record?.returnStatus === "Refunded"}
                // disabled={record?.returnStatus !== "Returning"}
              >
                Reject
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Card Information"
        open={refundModalVisible}
        onOk={() => {
          onSendArrivalNotification({
            orderItemId: refundModalVisible,
            type: "REFUND_CONFIRMATION",
          });
        }}
        okButtonProps={{
          loading: emailLoading,
          disabled: !last4OfCard || !cardType,
        }}
        onCancel={() => {
          setRefundModalVisible(false);
        }}
      >
        <label>Card Type*</label>
        <Input
          type="text"
          size="large"
          onChange={(e) => setCardType(e.target.value)}
          placeholder="e. g. Visa Card"
        />
        <label className="mt-3">Last 4 Digit*</label>
        <Input
          size="large"
          type="text"
          onChange={(e) => setLast4OfCard(e.target.value)}
          placeholder="e. g. 4242"
        />
      </Modal>
      <Modal
        title="Attachment"
        open={isShippingLavbelModalVisible}
        onOk={onSendShippingLabel}
        okButtonProps={{ loading: shippingLabelLoading }}
        onCancel={() => {
          setIsShippingLavbelModalVisible(false);
        }}
      >
        <Input
          accept=".pdf"
          type="file"
          onChange={(e) => setAttachemnt(e.target.files[0])}
        />
      </Modal>
      <Modal
        title="Reject note"
        open={isRejectModalVisible}
        onOk={() => {
          onSubmitRefund({
            orderItemId: isRejectModalVisible,
            returnStatus: "Rejected",
          });
        }}
        onCancel={() => {
          setIsRejectModalVisible(false);
          setNote("");
        }}
      >
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Enter rejection note..."
        />
      </Modal>
      <DynamicList
        title="All returned order items"
        data={allOrderReturnItemsList}
        columns={columns}
        loading={
          loading || statusLoading || emailLoading || shippingLabelLoading
        }
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            hideOnSinglePage
            onChange={onChangePagination}
          />
        }
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <div className="d-flex mb-3 align-items-center">
                {record?.returnShippingMethod === "CHILLFITRAVE" && (
                  <Button
                    className="mr-2"
                    type="primary"
                    // disabled={
                    //   record?.hasShippingLabelSent ||
                    //   record?.returnStatus !== "Accepted"
                    // }
                    onClick={() => setIsShippingLavbelModalVisible(record._id)}
                  >
                    {record?.hasShippingLabelSent ? "Resend" : "Send"} Shipping
                    Label
                  </Button>
                )}
                {record?.returnShippingMethod === "OWN" && (
                  <Button
                    className="mr-2"
                    type="primary"
                    // disabled={
                    //   record?.hasArrived || record?.returnStatus !== "Accepted"
                    // }
                    onClick={() =>
                      onSendArrivalNotification({
                        orderItemId: record._id,
                        type: "HAS_ARRIVED",
                      })
                    }
                  >
                    {record?.hasArrived ? "Resend" : "Send"} Arrived
                    Notification
                  </Button>
                )}
                {record?.returnPaymentMethod === "STORE_CREDIT" && (
                  <>
                    <Button
                      className="mr-2"
                      type="primary"
                      disabled={
                        record?.hasGiftCardSent ||
                        record?.returnStatus !== "Accepted"
                      }
                      onClick={() =>
                        onSendArrivalNotification({
                          orderItemId: record._id,
                          type: "CHILLFIT_CASH",
                        })
                      }
                    >
                      Send Chilfit Cash
                    </Button>
                    <p>
                      <b>Current balance: </b>

                      {(record?.order?.user?.chillfitCash || 0)
                        // +
                        // (record?.totalDiscountedPrice || 0) +
                        // (record?.totalTax || 0)
                        .toFixed(2)}
                    </p>
                  </>
                )}
                {record?.returnPaymentMethod === "ORIGINAL_PAYMENTS" && (
                  <Button
                    className="mr-2"
                    type="primary"
                    disabled={record?.returnStatus !== "Accepted"}
                    onClick={() => setRefundModalVisible(record._id)}
                  >
                    Send Original Payment Refund Notification
                  </Button>
                )}
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="d-flex">
                    <p className="mr-2">
                      <b>Shipping company:</b>
                    </p>
                    <p>{record?.shippingCompanyName}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex">
                    <p className="mr-2">
                      <b>Tracking number:</b>
                    </p>
                    <p>{record?.trackingNumber}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex">
                    <p className="mr-2">
                      <b>Shipping URL:</b>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        href={record?.shippingUrl}
                        rel="noreferrer"
                      >
                        {record?.shippingUrl}
                      </a>
                    </p>
                  </div>
                </div>

                {record?.returnItems?.map((data, index) => (
                  <div className="col-md-12" key={data._id}>
                    <div className="mt-2">
                      <p>
                        <b>Item #{index + 1}:</b>
                      </p>
                    </div>
                    <div className="row ml-3">
                      <div className="col-md-3">
                        <div className="mb-4">
                          <p className="">
                            <b>Name:</b>
                          </p>
                          <p>{data?.orderItem?.name}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-4">
                          <p className="">
                            <b>Quantity:</b>
                          </p>
                          <p>
                            {data?.quantity}/{data?.orderItem?.quantity}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-4">
                          <p className="">
                            <b>Return reason:</b>
                          </p>
                          <ul>
                            {data?.customerReturnReasons?.map((item, index) => (
                              <p key={item}>
                                {index + 1}. {item}
                              </p>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-4">
                          <p className="">
                            <b>Return note:</b>
                          </p>
                          <p>{data?.customerReturnNote}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      ></DynamicList>
    </>
  );
};

const getStatus = (status) => {
  switch (status) {
    case "Returning":
      return <Tag color="orange">{status}</Tag>;
    case "Refunded":
      return <Tag color="green">{status}</Tag>;
    case "Rejected":
      return <Tag color="red">{status}</Tag>;
    case "Accepted":
      return <Tag color="geekblue">{status}</Tag>;
    default:
      break;
  }
};
