import React from "react";
import { Button, Select, Tag } from "antd";
import { OrderStatus } from "../../config/constants";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const Status = ({
  checkOrderStatus,
  dataStatus,
  updateOrderStatus,
  edit,
  setEdit,
  isAllOrder,
  cancelNote,
  totalRefunded,
}) => {
  // control edit status
  const onEdit = () => {
    // setStatus(dataStatus);
    setEdit(true);
  };
  const closeEdit = () => {
    // setStatus(dataStatus);
    setEdit(false);
  };

  // order status function call
  const updateStatus = () => {
    closeEdit();
    updateOrderStatus();
  };
  return (
    <div className="shipping-details">
      <h4 className="d-flex align-items-center">
        Order Status:{" "}
        {edit ? (
          <div>
            <Select
              size="small"
              className="ml-2"
              style={{ width: "150px" }}
              onChange={(value) => checkOrderStatus(value)}
              value={dataStatus}
            >
              <Select.Option value={OrderStatus.Pending}>
                {OrderStatus.Pending}
              </Select.Option>
              {isAllOrder && (
                <Select.Option value={OrderStatus.Partial}>
                  {OrderStatus.Partial}
                </Select.Option>
              )}
              <Select.Option value={OrderStatus.Shipped}>
                {OrderStatus.Shipped}
              </Select.Option>
              <Select.Option value={OrderStatus.Delivered}>
                {OrderStatus.Delivered}
              </Select.Option>
              <Select.Option value={OrderStatus.Deleted}>
                {OrderStatus.Deleted}
              </Select.Option>
            </Select>
            <React.Fragment>
              <CheckOutlined
                className="m-2"
                style={{ fontSize: "16px" }}
                onClick={updateStatus}
              />
              <CloseOutlined onClick={closeEdit} style={{ fontSize: "16px" }} />
            </React.Fragment>
          </div>
        ) : (
          getStatus(dataStatus)
        )}
      </h4>
      <div className="d-block ant-card ant-card-bordered py-2">
        {dataStatus === OrderStatus.refunded && (
          <p>
            <b>Total refunded: </b> {totalRefunded?.toFixed(2)} USD
          </p>
        )}
      </div>
      <div className="d-block ant-card ant-card-bordered p-2">
        {dataStatus === OrderStatus.Canceled && cancelNote && (
          <p style={{ fontSize: "18px", color: "gray" }}>{cancelNote}</p>
        )}
      </div>
      {dataStatus !== OrderStatus.Canceled &&
        dataStatus !== OrderStatus.Delivered &&
        dataStatus !== OrderStatus.Deleted &&
        dataStatus !== OrderStatus.refunded && (
          <h6>
            {!edit && (
              <Button type="primary" onClick={onEdit}>
                Change Status
              </Button>
            )}
          </h6>
        )}
    </div>
  );
};

const getStatus = (status) => {
  switch (status) {
    case OrderStatus.PreOrder:
      return (
        <Tag className="ml-2" color="orange">
          {status}
        </Tag>
      );
    case OrderStatus.Pending:
      return (
        <Tag className="ml-2" color="orange">
          {status}
        </Tag>
      );
    case OrderStatus.Shipped:
      return (
        <Tag className="ml-2" color="green">
          {status}
        </Tag>
      );
    case OrderStatus.Partial:
      return (
        <Tag className="ml-2" color="green">
          {status}
        </Tag>
      );
    case OrderStatus.Delivered:
      return (
        <Tag className="ml-2" color="blue">
          {status}
        </Tag>
      );
    case OrderStatus.Canceled:
      return (
        <Tag className="ml-2" color="red">
          {status}
        </Tag>
      );
    case OrderStatus.refunded:
      return (
        <Tag className="ml-2" color="red">
          {status}
        </Tag>
      );
    case OrderStatus.Deleted:
      return (
        <Tag className="ml-2" color="red">
          {status}
        </Tag>
      );
    default:
      break;
  }
};
