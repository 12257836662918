import React, { useState } from "react";
import { Card, Collapse, Input, Modal, Popconfirm, Space, Tooltip } from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  MenuOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Description = ({ data = [], onChange }) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editDescIdx, setEditDescIdx] = useState("");
  console.log("data =======> ", data);
  // handle editor input
  const onEditorStateChange = (value) => {
    setDescription(value);
  };

  const addDescription = async () => {
    let desc = [...data];
    desc.push({ title, description });
    onChange(desc);
    closeModal();
  };
  // update faq api

  const editDescription = async () => {
    let desc = [...data];
    desc[editDescIdx] = { title, description };
    onChange(desc);
    closeModal();
  };

  const onDragEnd = (info) => {
    const sourceIndex = info?.source?.index;
    const destinationIndex = info?.destination?.index;
    if (
      destinationIndex === undefined ||
      destinationIndex === null ||
      sourceIndex === destinationIndex
    ) {
      return;
    }
    const updatedFaq = Array.from(data);
    const [movedItem] = updatedFaq.splice(sourceIndex, 1);
    updatedFaq.splice(destinationIndex, 0, movedItem);
    onChange(updatedFaq);
  };

  // delete faq question
  const deleteDescription = async (idx) => {
    let desc = [...data];
    desc.splice(idx, 1);
    onChange(desc);
  };

  // handle modal
  const closeModal = () => {
    setEditDescIdx("");
    setTitle("");
    setDescription("");
    setVisible(false);
  };

  // open modal and set data to state by selected description
  const openModal = ({ title, description, idx }) => {
    setEditDescIdx(idx);
    setTitle(title);
    setDescription(description);
    setVisible(true);
  };

  // extra
  const getExtra = ({ title, description, idx, provided }) => (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div className="mr-2" {...provided.dragHandleProps}>
          <MenuOutlined />
        </div>
        <div className="mr-2">
          <EditOutlined
            onClick={(e) => {
              openModal({ title, description, idx });
              e.stopPropagation();
            }}
          />
        </div>
        <div className="mr-2">
          <Popconfirm
            title="Are you sure?"
            okType="danger"
            onConfirm={(e) => {
              deleteDescription(idx);
              e.stopPropagation();
            }}
          >
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );

  const getExtraForCategoryCard = () => (
    <div className="d-flex justify-content-around align-items-center">
      <div className="mr-2">
        <Tooltip title="Add policy">
          <PlusCircleFilled
            style={{ cursor: "pointer" }}
            onClick={() => setVisible(true)}
          />
        </Tooltip>
      </div>
    </div>
  );

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgray" : "none",
    borderRadius: "8px",
    marginBottom: "8px",
    ...draggableStyle,
  });

  return (
    <Card
      className="mb-4 border p-3"
      headStyle={{ padding: "0", minHeight: "unset", marginBottom: "10px" }}
      title={<h6>Click plus icon to add more</h6>}
      extra={getExtraForCategoryCard()}
      bodyStyle={{ padding: "0" }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data.map(({ description, title }, idx) => (
                  <Draggable
                    key={idx + (title || "sdf")}
                    draggableId={idx + (title || "sdf")}
                    // draggableId={_id}
                    index={idx}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Collapse
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined
                              rotationslider={isActive ? 90 : 0}
                            />
                          )}
                          items={[
                            {
                              key: "1",
                              label: title,
                              extra: getExtra({
                                title,
                                description,
                                idx,
                                provided,
                              }),
                              children: (
                                <div className="col-12">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: description,
                                    }}
                                  />
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Space>
      <Modal
        open={visible}
        title={
          String(editDescIdx)
            ? "Update question and answer"
            : "Add question and answer"
        }
        onCancel={closeModal}
        okText={String(editDescIdx) ? "Update" : "Add"}
        okButtonProps={{
          disabled: !title || !description,
        }}
        onOk={String(editDescIdx) ? editDescription : addDescription}
      >
        <div className="mb-4">
          <label>Title*</label>
          <Input
            size="large"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
          />
        </div>
        <div>
          <label>Description*</label>
          <ReactQuill
            modules={Description.modules}
            value={description || ""}
            onChange={onEditorStateChange}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default Description;

Description.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
